<template>
  <div style="padding-right: 3.4em">
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showsnackbar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex px-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card class="mb-1">
                <v-card-title>Edit Customer </v-card-title>
              </v-card>

              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="ID"
                        outlined
                        dense
                        v-model="customer.customerID"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Name of the Company"
                        :rules="companyRules"
                        outlined
                        dense
                        v-model="customer.companyName"
                      ></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <v-text-field
                        ref="contactPerson"
                        color="black"
                        label="Contact Person"
                        outlined
                        dense
                        v-model="customer.contactPerson"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="email"
                        color="black"
                        label="Email ID"
                        :rules="emailRules"
                        outlined
                        dense
                        v-model="customer.email"
                      ></v-text-field>
                    </v-col>

                    <v-col md="2">
                      <v-text-field
                        ref="phone"
                        color="black"
                        label="Phone Number"
                        outlined
                        :rules="phoneRules"
                        dense
                        v-model="phoneno"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col md="2">
                      <v-text-field
                        ref="fax"
                        color="black"
                        label="Fax"
                        outlined
                        dense
                        v-model="fax"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 13px">Billing Address</span>
                  </v-row>
                  <v-row dense>
                    <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Address"
                        outlined
                        dense
                        v-model="customer.address"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="City"
                        outlined
                        dense
                        v-model="customer.city"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-select
                        :items="states"
                        ref="state"
                        color="black"
                        label="State"
                        outlined
                        dense
                        v-model="customer.stateId"
                        item-text="state"
                        item-value="_id"
                      ></v-select>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Zip"
                        outlined
                        dense
                        v-model="customer.zip"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 13px">Shipping Address</span>
                  </v-row>
                  <v-row dense>
                    <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Address"
                        outlined
                        dense
                        v-model="customer.shipAddress"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="City"
                        outlined
                        dense
                        v-model="customer.shipCity"
                      ></v-text-field>
                    </v-col>

                    <v-col md="3">
                      <v-select
                        :items="states"
                        ref="state"
                        color="black"
                        label="State"
                        outlined
                        dense
                        v-model="customer.shipStateId"
                        item-text="state"
                        item-value="_id"
                      ></v-select>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Zip"
                        outlined
                        dense
                        v-model="customer.shipZip"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                    <v-col cols="12" sm="3" md="4">
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="190px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="initialDate"
                            label="Initial"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="customer.initialDate"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="3" md="4">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="lastDate"
                            label="Final"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="customer.lastDate"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="3" md="4">
                      <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="birthDate"
                            label="DOB"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="customer.birthDate"
                          @input="menu3 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Nature"
                        outlined
                        dense
                        v-model="customer.nature"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-select
                        :items="allStatus"
                        label="Status"
                        dense
                        outlined
                        v-model="customer.status"
                      ></v-select>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Agent Code"
                        outlined
                        disabled
                        dense
                        v-model="customer.agentCode"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Means"
                        outlined
                        dense
                        v-model="customer.means"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Rating"
                        outlined
                        dense
                        v-model="customer.rating"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Acc No"
                        outlined
                        dense
                        v-model="customer.accountNo"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-select
                        :items="terms"
                        ref="terms"
                        color="black"
                        label="Terms"
                        hide-details
                        outlined
                        item-text="name"
                        item-value="_id"
                        v-model="customer.termId"
                        dense
                      ></v-select>
                    </v-col>

                    <v-col md="4">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Age"
                        outlined
                        dense
                        v-model="customer.age"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="YTD"
                        outlined
                        dense
                        v-model="customer.ytd"
                      ></v-text-field>
                    </v-col>
                    <v-col md="12">
                      <v-textarea
                        ref="Comments"
                        color="black"
                        label="Comments"
                        dense
                        auto-grow
                        outlined
                        rows="7 "
                        row-height="15"
                        v-model="customer.description"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <!-- <v-layout wrap justify-center>
                    <v-flex xs12 md12 pb-4 text-center>
                      <v-btn
                        
                        dark
                        color="primary"
                        depressed
                        class="text-capitalize"
                        @click="validate()"
                        :ripple="false"
                      >
                        Submit
                      </v-btn>
                    </v-flex>
                  </v-layout> -->
                </v-card-title>
              </v-card>

              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 14px"><b>Notes</b></span>
                  </v-row>

                  <v-row dense>
                    <v-col md="2">
                      <v-row dense>
                        <v-col md="12">
                          <v-text-field
                            ref="Agent"
                            color="black"
                            label="Agent"
                            dense
                            disabled
                            outlined
                            v-model="agentCode"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="10">
                      <v-row dense v-for="(item, i) in notes" :key="i">
                        <v-col md="10">
                          <v-text-field
                            
                            ref="Notes"
                            color="black"
                            label="Note"
                            dense
                            outlined
                            v-model="item.note"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col md="1">
                          <v-btn
                            v-if="i > 0"
                            class="mx-2"
                            depressed
                            fab
                            dark
                            small
                            color="error"
                            @click="removee(i)"
                          >
                            <v-icon dark> mdi-minus </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col md="1">
                          <v-btn
                            v-if="i == notes.length - 1"
                            fab
                            depressed
                            small
                            color="primary"
                            @click="update"
                          >
                            <v-icon dark> mdi-plus </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

              <v-card class="mb-4" outlined>
                <v-card-title>
                  <v-layout wrap justify-center>
                    <v-flex xs12 md12 text-center>
                      <v-btn
                        dark
                        color="primary"
                        depressed
                        class="text-capitalize"
                        @click="validate()"
                        :ripple="false"
                      >
                        Submit
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <v-navigation-drawer absolute permanent right width="55">
      <v-list nav dense>
        <!-- <v-list-item link to="" title="Excel">
          <v-list-item-icon>
            <v-icon>mdi-file-excel</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item> -->
        <!-- <v-list-item link title="Pdf">
          <v-list-item-icon>
            <v-icon color="primary">mdi-file-pdf</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item> -->
        <!-- <v-list-item link to="/addCustomer" title="Add">
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
//import store from "../../store";
import axios from "axios";
export default {
  data() {
    return {
      valid: true,
      companyRules: [
        (v) => !!v || "Company Name is required",
        (v) =>
          (v && v.length <= 50) ||
          "Company Name must be less than 50 characters",
      ],
      numberrules: [
        // (v) => !isNaN(parseFloat(v)) || "Number is Expected",
        (v) => v >= 0 || "Number is Expected",
      ],

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [(v) => !!v || "Phone is required"],
      customer: {
        id: null,
        companyName: null,
        contactPerson: null,
        email: null,
        nature: null,
        address: null,
        city: null,
        stateId: null,
        countryId: null,
        zip: null,
        phone: null,
        fax: null,
        shipAddress: null,
        shipCity: null,
        shipStateId: null,
        shipCountryId: null,
        shipZip: null,
        birthDate: null,
        initialDate: null,
        lastDate: null,
        termId: null,
        age: null,
        agentCode: null,
        ytd: null,
        means: null,
        rating: null,
        accountNo: null,
        status: "Active",
        description: null,
      },
      notes: [
        {
          note: "",
        },
      ],
      phoneno:null,
      fax:null,
      showsnackbar: false,
      msg: null,
      ServerError: false,
      validateInput: null,
      timeout: 5000,
      appLoading: false,
      msg1: null,
      userData: [],
      allStatus: [],
      terms: [],
      states: [],
      menu1: false,
      menu3: false,
      menu2: false,
     agentCode:localStorage.getItem("agentCode"),
    };
  },

  beforeMount() {
    this.getCustomer();
    this.stateList();
    this.termList();
    this.statusList();
  },
  computed: {
       initialDate () {
      return this.formatDate(this.customer.initialDate)
    },
      lastDate () {
      return this.formatDate(this.customer.lastDate)
    },
      birthDate () {
      return this.formatDate(this.customer.birthDate)
    },
  },
  watch:{
    phoneno() {
      //  console.log("here")
      var str = this.phoneno;
      var ind;
      if (str.length == 3) {
        ind = str.substring(0, 3);
        this.phoneno = ind + "-";
      }
      if (str.length == 8) {
        ind = str.substring(0, 7);
        this.phoneno = ind + "-";
      }
    },
     fax() {
      //  console.log("here")
      var str = this.fax;
      var ind;
      if (str.length == 3) {
        ind = str.substring(0, 3);
        this.fax = ind + "-";
      }
      if (str.length == 8) {
        ind = str.substring(0, 7);
        this.fax = ind + "-";
      }
    },
  },
  methods: {
    update() {
      this.notes.push({
        note: "",
      });
    },
    removee(index) {
      this.notes.splice(index, 1);
    },
    validate() {
      if (this.$refs.form.validate()) this.editCustomer();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getCustomer() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/customer/view/" + this.$route.params.id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.customer = response.data.data;
            this.phoneno = response.data.data.phone;
            this.fax = response.data.data.fax;
            if (response.data.data.initialDate)
              this.customer.initialDate = this.ChangeFormateDate(
                response.data.data.initialDate
              );
            if (response.data.data.lastDate)
              this.customer.lastDate = this.ChangeFormateDate(
                response.data.data.lastDate
              );
            if (response.data.data.birthDate)
              this.customer.birthDate = this.ChangeFormateDate(
                response.data.data.birthDate
              );
              if (this.customer.notes.length>0) this.notes = this.customer.notes;
            // console.log(this.notes)
            else{
              this.notes=[{note:""}];
            }
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    stateList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/states",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.states = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    termList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/term/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.terms = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    statusList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/customer/status",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.allStatus = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    editCustomer() {
      this.appLoading = true;
      this.customer.phone = this.phoneno;
      this.customer.fax = this.fax;
      axios({
        method: "POST",
        url: "/customer/edit/" + this.$route.params.id,
        data: this.customer,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
             if(this.notes.length>0)
            this.editNotes();
            this.$notify({
              group: "appNotify",
              title: "ABB Labels",
              text: "Customer Edited!",
            });
             this.$router.push("/Customer/" + this.$route.params.id);
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
     editNotes(){
       axios({
        method: "POST",
        url: "/note/add",
        data: {
         customerId: this.$route.params.id,
         notes:this.notes
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
           console.log(response.data.msg) 
           
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    ChangeFormateDate(oldDate) {
      var newdateFormat = oldDate.toString().split("T");
      return newdateFormat[0];
    },
     formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
  
  },
};
</script>

<style >
.v-text-field--outlined.v-input--dense .v-label {
  top: 8px !important;
  font-size: 11px !important;
  font-weight: bold;
  color: black;
}
.v-text-field input {
  color: black !important;
}
.v-text-field__slot textarea {
  color: black !important;
}
.v-card__title {
  line-height: 1rem !important;
}
.titlestyle {
  padding-bottom: 0px !important;
}
</style>